import Reviews from '../../components/shared/Reviews'
import { Tile } from '../structure/Tile'
import { useTranslation } from 'next-i18next'
import { TileImage } from '../structure/TileImage'
import { useLocalStorage } from '../LocalStorageProvider'
import { formatPrice } from '../../lib/helper/price'
import { getHref } from '../../lib/helper/helper'
import { GLOBAL_FALLBACK_IMAGE } from '../../lib/constants'
import Link from '../overwrites/Link'

type Props = {
  item: ReducedProduct
  isSlider?: boolean
  titleAs?: 'span' | 'h3'
  type?: 'product'
}

export function ProductTile({ item, isSlider, titleAs = 'span', type }: Props) {
  const { t, i18n } = useTranslation('common')
  const {
    h1: h1WithWildcards = '',
    image,
    product_badge,
    reviews,
    price_from,
    _id,
    has_product_page,
    location,
  } = item
  const { name: locationName } = location
  const h1 = h1WithWildcards?.replace('[city]', locationName)
  const { addLastViewedProduct } = useLocalStorage()
  const formattedPrice = formatPrice({
    price: (price_from?.EUR || 0).toString(),
    currency: 'EUR',
  })
  const badgeBackgrounds = {
    best_seller: 'bg-highlight',
    new: 'bg-primary',
    family_friendly: 'bg-success',
    private_tour: 'bg-primary',
    combi_offer: 'bg-primary',
  }

  const renderH1 = () => {
    const substrings = ['Kombi:', 'Combi:', 'Privat:', 'Private:']
    const regex = new RegExp(`(${substrings.join('|')})`, 'i')
    const parts = h1?.split(regex) || []

    const TitleComponent = titleAs === 'h3' ? 'h3' : 'span'

    return (
      <TitleComponent>
        {parts
          .filter((p) => p)
          .map((part, index) => {
            if (
              substrings.some(
                (substring) => part.toLowerCase() === substring.toLowerCase(),
              )
            ) {
              return (
                <span key={index} className="text-primary">
                  {part}
                </span>
              )
            }
            return <span key={index}>{part}</span>
          })}
      </TitleComponent>
    )
  }

  return (
    <Link
      href={getHref(item, i18n.language as AppLocale)}
      onClick={() => addLastViewedProduct(_id)}
      passHref
      rel={has_product_page ? '' : 'noopener noreferrer'}
      target={has_product_page ? '' : '_blank'}
    >
      <Tile type={type || 'product'} isSlider={isSlider}>
        <div className="flex flex-col justify-between h-full">
          <TileImage
            image={image || GLOBAL_FALLBACK_IMAGE}
            alt={h1 + ' ' + t('tile') + ' - SIGHT2SIGHT'}
            title={h1 + ' ' + t('tile') + ' - SIGHT2SIGHT'}
            width={318 * 1.5}
            height={154 * 1.5}
          />
          {product_badge && product_badge !== 'none' && (
            <span
              className={`${badgeBackgrounds[product_badge as keyof typeof badgeBackgrounds]} absolute z-30 top-0 right-0 text-white px-2 py-1 text-xs font-medium rounded-bl-lg`}
            >
              {t(product_badge)}
            </span>
          )}
          <div
            className="w-full flex flex-col justify-between py-1 px-2"
            style={{ minHeight: '70px' }}
          >
            <span className="text-basis text-sm font-medium">{renderH1()}</span>
            <div className="flex justify-between items-center">
              <div className="flex-1 min-w-0">
                <Reviews {...reviews} name={h1} isTile />
              </div>
              {price_from !== undefined && (
                <div className="flex-shrink-0 ml-2 text-basis text-sm font-medium whitespace-nowrap">
                  <span className="text-xs font-normal">{t('from')}</span>{' '}
                  <span>{formattedPrice}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Tile>
    </Link>
  )
}
